import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { validateYupSchema } from "formik";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PMListboxFormik(props) {
  const { label, options, name, values, setFieldValue, onChange } = props;

  const findSelectedById = (id) => {
    return options.find((option) => option.value === id);
  };

  const selected = props?.selected
    ? props?.selected
    : values
    ? values[name]
    : null;

  const [currentSelected, setCurrentSelected] = useState(
    findSelectedById(selected)
  );

  const { t } = useTranslation();

  const handleOnChange = (data) => {
    setCurrentSelected(data);
    if (setFieldValue) setFieldValue(name, data.value);
  };

  useEffect(() => {
    if (onChange) {
      onChange(currentSelected);
    }
  }, [currentSelected]);

  useEffect(() => {
    setCurrentSelected(findSelectedById(selected));
  }, [selected]);

  return (
    <Listbox name={name} value={currentSelected} onChange={handleOnChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="flex items-center">
                {/* <img src={selected.avatar} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" /> */}
                {currentSelected?.icon ? (
                  <currentSelected.icon className="w-5" />
                ) : (
                  ""
                )}
                <span className="ml-3 block truncate">
                  {t(currentSelected?.label) || "None"}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {option?.avatar ? (
                            <img
                              src={option.avatar}
                              alt=""
                              className="h-6 w-6 flex-shrink-0 rounded-full"
                            />
                          ) : option?.icon ? (
                            <option.icon className="w-5" />
                          ) : (
                            ""
                          )}
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {t(option.label)}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
