import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteQuality,
  fetchQuality,
  printQuality,
} from "../../../../api/quality/quality";
import {
  axiosWithToast,
  HtmlTextFormatter,
} from "../../../../components/Utils";
import { PencilIcon, PrinterIcon } from "@heroicons/react/24/outline";
import { ResourceLoading } from "../../../../components/utils/ResourceLoading";
import { ResourceError } from "../../../../components/utils/ResourceError";
import { ResourceEmpty } from "../../../../components/utils/ResourceEmpty";
import DashboardHeader from "../../../../components/dashboard/dashboardHeader";
import QualityDialog from "../../../../components/dashboard/quality/qualities/quality/dialog";
import { PrintDialog } from "../../../../components/utils/printDialog";

export const QualityView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    quality: {},
  });

  const [printDialogOpen, setPrintDialogOpen] = useState(false);

  const getQuality = async () => {
    setItemState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    axiosWithToast(
      fetchQuality(id),
      () => {},
      () => {},
      (data) => {
        setItemState((prevState) => ({
          ...prevState,
          quality: data,
          isLoading: false,
        }));
      },
      "Laster retningslinje..."
    );
  };

  useEffect(() => {
    getQuality();
  }, [dispatch, id, open]);

  const handleOnRefresh = (data) => {
    if (data?.id) {
      setItemState((prevState) => ({
        ...prevState,
        quality: data,
      }));
    } else {
      getQuality();
    }
  };

  const { quality, isLoading, error, errors } = itemState;

  const [qualityDialogOpen, setQualityDialogOpen] = useState(false);

  const headerMeta = [];

  const headerActions = [
    {
      component: null,
      label: "Skriv ut",
      description: "",
      path: null,
      action: () => setPrintDialogOpen(true),
      icon: PrinterIcon,
      textColor: "text-teal-700",
      bgColor: "bg-white",
      hidden: false,
      menu: false,
    },
  ];

  const Content = () => {
    if (isLoading) {
      return <ResourceLoading size="base" />;
    }

    if (error) return <ResourceError errors={errors} />;

    if (!quality?.id) {
      return <ResourceEmpty />;
    }

    return (
      <>
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max">
          <section
            className="lg:max-w[7xl]"
            aria-labelledby="applicant-information-title"
          >
            <div className="bg-white shadow sm:rounded-lg">
              <div className="flex items-center px-4 py-5 sm:px-6 w-full">
                <div className="block w-[60%]">
                  <h2
                    id="applicant-information-title"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {quality?.title}
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {quality?.chapter?.title}
                  </p>

                  <div className="htmlContent mt-5 sm:mt-6 ">
                    <HtmlTextFormatter string={quality?.text} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="space-y-6 lg:col-span-2 lg:col-start-1 sm:col-span-2 max-sm:mt-5 max"></div>
      </>
    );
  };

  return (
    <>
      <DashboardHeader
        subtitle={quality?.title}
        actions={headerActions}
        title={"Retningslinjer"}
        meta={headerMeta}
        mainButton={{
          label: "Edit",
          action: () => setQualityDialogOpen(true),
          path: null,
          color: "",
          icon: PencilIcon,
        }}
      >
        <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-1 mb-20">
          <Content />

          <QualityDialog
            open={qualityDialogOpen}
            onClose={() => {
              setQualityDialogOpen(false);
            }}
            selected={quality}
            onRefresh={(data) => {
              handleOnRefresh(data);
            }}
          />
        </div>
      </DashboardHeader>

      <PrintDialog
        item={quality}
        module="quality"
        typeOptions={[{ label: "Standard", value: "Standard" }]}
        axiosMethod={printQuality}
        open={printDialogOpen}
        isLoading={isLoading}
        // onLoading={(state) => setIsLoading(state)}
        onClose={() => setPrintDialogOpen(false)}
        onRefresh={handleOnRefresh}
      />
    </>
  );
};
